import React from 'react';
import { Typography, Container, Link } from '@mui/material';

const TermsOfService = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Terms of Service
      </Typography>
      <Typography variant="body1" gutterBottom>
        Welcome to Open Planner! These Terms of Service ("Terms") govern your
        use of our educational technology services provided by Open Planner
        ("we," "our," or "us") through our website, mobile application, or any
        other platform we offer (collectively, the "Services"). By accessing or
        using the Services, you ("you" or "user") agree to be bound by these
        Terms. If you do not agree to these Terms, please refrain from using our
        Services.
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
        Acceptance of Terms
      </Typography>
      <Typography variant="body1" gutterBottom>
        By using our Services, you acknowledge that you have read, understood,
        and agreed to these Terms, and you represent that you have the legal
        capacity to enter into a binding agreement. If you are using the
        Services on behalf of an organization or entity, you further represent
        that you have the authority to bind them to these Terms.
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
        Use of Services
      </Typography>
      <Typography variant="body1" gutterBottom>
        a. Eligibility: You must be at least 13 years old to use our Services.
        If you are under 13, you may only use the Services under the supervision
        of a parent, legal guardian, or teacher, and with their consent.
        <br />
        b. Account Creation: To access certain features of the Services, you may
        need to create an account. You are responsible for providing accurate
        and up-to-date information during the account registration process. You
        are also responsible for maintaining the confidentiality of your account
        credentials and are liable for all activities that occur under your
        account.
        <br />
        c. Prohibited Activities: While using our Services, you agree not to:
        <ul>
          <li>
            Violate any applicable laws, regulations, or third-party rights
          </li>
          <li>
            Impersonate any person or entity, misrepresent your affiliation with
            a person or entity
          </li>
          <li>Use the Services for any illegal or unauthorized purpose</li>
          <li>
            Attempt to interfere with the proper functioning of the Services
          </li>
          <li>
            Distribute unsolicited or unauthorized advertising, promotional
            materials, or spam
          </li>
          <li>
            Engage in any conduct that could harm Open Planner's reputation or
            goodwill
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
        Content and Intellectual Property
      </Typography>
      <Typography variant="body1" gutterBottom>
        a. User Content: By using our Services, you may submit, post, or
        transmit content, including but not limited to text, images, videos, and
        other materials (collectively, "User Content"). You retain ownership of
        your User Content. However, by submitting User Content to Open Planner,
        you grant us a non-exclusive, worldwide, royalty-free, transferable, and
        sublicensable license to use, reproduce, distribute, modify, adapt, and
        publicly display your User Content for the purpose of providing and
        improving our Services.
        <br />
        b. Open Planner Content: The Services may contain content provided by
        us, such as text, graphics, images, logos, icons, and software ("Open
        Planner Content"). Open Planner Content is protected by copyright,
        trademark, and other intellectual property laws. You may not use,
        reproduce, distribute, modify, or create derivative works of Open
        Planner Content without our explicit written consent.
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
        Privacy
      </Typography>
      <Typography variant="body1" gutterBottom>
        Your privacy is essential to us. Our Privacy Policy outlines how we
        collect, use, and disclose your personal information and can be found{' '}
        <Link href="link-to-privacy-policy">here</Link>. By using our Services,
        you consent to the practices described in the Privacy Policy.
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
        Third-Party Services
      </Typography>
      <Typography variant="body1" gutterBottom>
        Our Services may include links, integrations, or other connections to
        third-party websites, applications, or services. We have no control over
        these third-party services and are not responsible for their content,
        functionality, or privacy practices. Your interactions with third-party
        services are solely between you and the third party, and you should
        review the terms and privacy policies of such third-party services
        before using them.
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
        Modifications to the Services and Terms
      </Typography>
      <Typography variant="body1" gutterBottom>
        We reserve the right to modify or discontinue the Services, temporarily
        or permanently, with or without notice. We may also update these Terms
        from time to time. Any changes to the Terms will be effective upon
        posting the updated version on our website or notifying you through
        other reasonable means. Your continued use of the Services after the
        changes will signify your acceptance of the updated Terms.
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
        Termination
      </Typography>
      <Typography variant="body1" gutterBottom>
        You may stop using our Services at any time, and we reserve the right to
        terminate your access to the Services for any reason without notice.
        Upon termination, your license to use the Services will be revoked, and
        any provisions in these Terms that should reasonably survive termination
        will remain in effect.
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
        Disclaimer of Warranties
      </Typography>
      <Typography variant="body1" gutterBottom>
        THE SERVICES ARE PROVIDED ON AN "AS-IS" AND "AS AVAILABLE" BASIS,
        WITHOUT ANY WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. WE
        DISCLAIM ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, IMPLIED
        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
        NON-INFRINGEMENT, OR ACCURACY.
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
        Limitation of Liability
      </Typography>
      <Typography variant="body1" gutterBottom>
        IN NO EVENT SHALL OPEN PLANNER, ITS AFFILIATES, OFFICERS, EMPLOYEES,
        AGENTS, OR LICENSORS BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
        EXEMPLARY, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH
        THE SERVICES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF
        PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES, EVEN IF WE
        HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
        Indemnification
      </Typography>
      <Typography variant="body1" gutterBottom>
        You agree to indemnify and hold Open Planner, its affiliates, officers,
        employees, agents, and licensors harmless from any claims, demands,
        losses, liabilities, and expenses (including attorneys' fees) arising
        out of or in connection with your use of the Services, your User
        Content, or your violation of these Terms.
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
        Governing Law and Dispute Resolution
      </Typography>
      <Typography variant="body1" gutterBottom>
        These Terms shall be governed by and construed in accordance with the
        laws of [Jurisdiction], without regard to its conflict of law
        principles. Any dispute arising out of or relating to these Terms or the
        Services shall be exclusively submitted to the competent courts of
        [Jurisdiction].
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
        Miscellaneous
      </Typography>
      <Typography variant="body1" gutterBottom>
        a. Entire Agreement: These Terms constitute the entire agreement between
        you and Open Planner regarding the Services and supersede all prior
        understandings, agreements, or representations.
        <br />
        b. Waiver: The failure of Open Planner to enforce any right or provision
        of these Terms shall not constitute a waiver of that right or provision.
        <br />
        c. Severability: If any provision of these Terms is found to be
        unenforceable or invalid, that provision shall be limited or eliminated
        to the minimum extent necessary, and the remaining provisions of these
        Terms shall remain in full force and effect.
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ mt: 3 }}>
        Thank you for choosing Open Planner! If you have any questions or
        concerns about these Terms, please contact us at openplanner3@gmail.com.
      </Typography>
    </Container>
  );
};

export default TermsOfService;
