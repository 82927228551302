import React, { useEffect, useState } from 'react';
import EventCard from './EventCard';
import { addUserEvent } from 'components/API/EventAPI';
import {
  Container,
  Grid,
  Button,
  Stack,
  Input,
  Alert,
  AlertTitle,
  Paper,
  TextField,
  Popover,
} from '@mui/material';
import { getUserCalendar } from 'components/API/CalendarAPI';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DigitalClock } from '@mui/x-date-pickers/DigitalClock';
import dayjs from 'dayjs';
import { DateCalendar } from '@mui/x-date-pickers';

function EventsBar(props) {
  const events = props.events;
  const [showAll, setShowAll] = useState(false);
  let display = showAll ? events : events.slice(0, 5);
  return (
    <>
      {events.length > 0 ? (
        <Grid item sx={{ overflowY: 'auto', maxHeight: '130vh' }}>
          {display.map((even) => even)}
        </Grid>
      ) : null}
      {events.length > 5 ? (
        showAll ? (
          <Button onClick={() => setShowAll(false)}>Hide</Button>
        ) : (
          <Button onClick={() => setShowAll(true)}>Show All</Button>
        )
      ) : null}
    </>
  );
}
const EventStack = ({ eventsArray, searchFunction, cal }) => {
  const [title, settitle] = useState('');
  const [body, setbody] = useState('');
  const [start, setstart] = useState('');
  const [end, setend] = useState('');
  const [category, setcategory] = useState('');
  const [calendar, setCalendar] = useState('');
  const [alert, setAlert] = useState();

  const [events, setEvents] = useState([]);

  const handleTitleChange = (e) => {
    settitle(e.target.value);
  };
  const handleBodyChange = (e) => {
    setbody(e.target.value);
  };
  console.log(eventsArray);
  const handleAddEvent = (e) => {
    console.log(calendar);
    let even = {
      title: title,
      body: title,
      calendar: calendar['id'],
      start:
        dayjs(startDateIn).format('YYYY-MM-DD') +
        'T' +
        dayjs(startTime, 'hh:mm A').format('HH:mm:ss'),
      end:
        dayjs(endDateIn).format('YYYY-MM-DD') +
        'T' +
        dayjs(endTime, 'hh:mm A').format('HH:mm:ss'),
      category: 'time',
      isCompleted: false,
      page: 1,
    };
    console.log(even);
    addUserEvent(even).then((response) => {
      even = response.data;
    });
    const currEvents = events;
    currEvents.push(
      <EventCard
        key={even['id']}
        even={even}
        searchFunction={searchFunction}
      />,
    );
    setEvents(currEvents);
    settitle('');
    setbody('');
    // setstart("");
    // setend("");
    setStartTime('12:00 AM');
    setEndTime('12:00 AM');
    setDate('');
    setDateEnd('');
    setEditEventsVisible(false);
  };

  const [startDateIn, setStartDateIn] = React.useState();
  const [endDateIn, setEndDateIn] = React.useState();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElTo, setAnchorElTo] = React.useState(null);
  const [startTime, setStartTime] = React.useState('12:00 AM');
  const [endTime, setEndTime] = React.useState('12:00 AM');
  const [date, setDate] = React.useState();
  const [dateEnd, setDateEnd] = React.useState();
  const [anchorElDate, setAnchorElDate] = React.useState(null);
  const [anchorElDateEnd, setAnchorElDateEnd] = React.useState(null);
  const [editEventsVisible, setEditEventsVisible] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClosePop = () => {
    setAnchorEl(null);
  };
  const handleClickTo = (event) => {
    setAnchorElTo(anchorElTo ? null : event.currentTarget);
  };
  const handleClosePopTo = () => {
    setAnchorElTo(null);
  };
  const handleClickDate = (event) => {
    setAnchorElDate(anchorElDate ? null : event.currentTarget);
  };

  const handleClickDateEnd = (event) => {
    setAnchorElDateEnd(anchorElDateEnd ? null : event.currentTarget);
  };
  const handleClosePopDate = () => {
    setAnchorElDate(null);
  };

  const handleClosePopDateEnd = () => {
    setAnchorElDateEnd(null);
  };

  const handleCalendarClick = (event) => {
    const date = dayjs(event.$d);
    setStartDateIn(date.format('YYYY-MM-DDTHH:mm:ss'));
    const formattedDate = date.format('ddd, MMMM DD');
    setDate(formattedDate);
  };

  const handleCalendarClickEnd = (event) => {
    const date = dayjs(event.$d);
    setEndDateIn(date.format('YYYY-MM-DDTHH:mm:ss'));
    const formattedDate = date.format('ddd, MMMM DD');
    setDateEnd(formattedDate);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const openTo = Boolean(anchorElTo);
  const idTo = openTo ? 'simple-popper-to' : undefined;
  const openDate = Boolean(anchorElDate);
  const openDateEnd = Boolean(anchorElDateEnd);
  const idDate = openDate ? 'simple-popper-date' : undefined;
  const idDateEnd = openDateEnd ? 'simple-popper-date-end' : undefined;

  useEffect(() => {
    console.log('oiansdi');
    getUserCalendar(cal).then((response) => {
      setCalendar(response);
    });
    const currEvents = [];
    eventsArray.map((even) => {
      currEvents.push(
        <EventCard
          key={even['id']}
          even={even}
          searchFunction={searchFunction}
        />,
      );
    });
    // if (currEvents.length < 1) {
    // setAlert(
    //   <Alert severity="error">
    //     <AlertTitle>Error</AlertTitle>
    //     Apologies. We were unable to read this syllabus. Add assignments
    //     below.
    //   </Alert>,
    // );
    //}
    setEvents(currEvents);
  }, [searchFunction]);

  function removeHTML(html){
    var div = document.createElement("div");
    div.innerHTML = html;
    return div.innerText || "";
  }
  return (
    <>
      <Stack>
        {alert && (
          <>
            {alert}
            <br></br>
          </>
        )}
        <EventsBar events={events} />
        {/* <EventForm calendar={calendar} /> */}
        {editEventsVisible ? (
          <Container
            sx={{
              boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
              borderRadius: '8px',
              p: 2,
              mt: 2,
            }}
          >
            <Stack sx={{ width: 300 }}>
              <TextField
                type="text"
                name="title"
                placeholder="Title"
                sx={{ m: 2, fontSize: 'var(--joy-fontSize-md)' }}
                value={removeHTML(title)}
                onChange={handleTitleChange}
                multiline
                rows={3}
                fullWidth
                variant="standard"
              />
              {/* <Input
              type="text"
              name="body"
              placeholder="Body"
              sx={{ m: 2, fontSize: "var(--joy-fontSize-md)" }}
              value={body}
              onChange={handleBodyChange}
            /> */}
              {/* <Input
              type="date"
              name="start"
              slotProps={{
                input: {
                  min: "2018-06-07T00:00",
                  max: "2018-06-14T00:00",
                  placeholder: "Start Date in %Y-%m-%dT%H:%M:%S format",
                },
              }}
              sx={{ m: 2, fontSize: "var(--joy-fontSize-md)" }}
              value={start}
              onChange={handleStartTimeChange}
            /> */}
              <Grid container>
                <Grid item xs={6}>
                  <TextField
                    id="name"
                    label="Date"
                    value={dayjs(date).format('ddd, MMMM DD')}
                    type="text"
                    fullWidth
                    size="small"
                    variant="standard"
                    onClick={(event) => {
                      handleClickDate(event);
                    }}
                  />
                  <Popover
                    id={idDate}
                    open={openDate}
                    anchorEl={anchorElDate}
                    onClose={handleClosePopDate}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateCalendar
                        value={dayjs(startDateIn)}
                        onChange={(newValue) => {
                          handleCalendarClick(newValue);
                          handleClosePopDate();
                        }}
                      />
                    </LocalizationProvider>
                  </Popover>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="name"
                    label="From"
                    fullWidth
                    size="small"
                    variant="standard"
                    onClick={(event) => {
                      handleClick(event);
                    }}
                    value={startTime}
                    InputProps={{
                      style: { caretColor: 'transparent' },
                    }}
                  />
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClosePop}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DigitalClock
                        // value={startTime}
                        onChange={(event) => {
                          let formattedDate = dayjs(event.$d).format('h:mm A');
                          setStartTime(formattedDate);
                          handleClosePop();
                        }}
                      />
                    </LocalizationProvider>
                  </Popover>
                </Grid>
              </Grid>
              {/* <Input
              type="date"
              name="end"
              placeholder="End Date in %Y-%m-%dT%H:%M:%S format"
              sx={{ m: 2, fontSize: "var(--joy-fontSize-md)" }}
              value={end}
              onChange={handleEndTimeChange}
            /> */}
              <Grid container>
                <Grid item xs={6}>
                  <TextField
                    id="name"
                    label="Date"
                    value={dayjs(dateEnd).format('ddd, MMMM DD')}
                    type="text"
                    fullWidth
                    size="small"
                    variant="standard"
                    onClick={(event) => {
                      handleClickDateEnd(event);
                    }}
                  />
                  <Popover
                    id={idDateEnd}
                    open={openDateEnd}
                    anchorEl={anchorElDateEnd}
                    onClose={handleClosePopDateEnd}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateCalendar
                        value={dayjs(endDateIn)}
                        onChange={(newValue) => {
                          handleCalendarClickEnd(newValue);
                          handleClosePopDateEnd();
                        }}
                      />
                    </LocalizationProvider>
                  </Popover>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="name"
                    label="To"
                    fullWidth
                    size="small"
                    variant="standard"
                    onClick={(event) => {
                      handleClickTo(event);
                    }}
                    value={endTime}
                    InputProps={{
                      style: { caretColor: 'transparent' },
                    }}
                  />
                  <Popover
                    id={idTo}
                    open={openTo}
                    anchorEl={anchorElTo}
                    onClose={handleClosePopTo}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DigitalClock
                        // value={endTime}
                        onChange={(event) => {
                          let formattedDate = dayjs(event.$d).format('h:mm A');

                          setEndTime(formattedDate);
                          handleClosePopTo();
                        }}
                      />
                    </LocalizationProvider>
                  </Popover>
                </Grid>
              </Grid>
              <Button onClick={handleAddEvent}>Add Event</Button>
            </Stack>
          </Container>
        ) : (
          <Paper
            style={{
              margin: '20px 0',
              border: '2px dotted #dd70dd',
              backgroundColor: 'transparent',
              padding: '10px 20px',
              fontSize: '16px',
              cursor: 'pointer',
              color: '#dd70dd',
              transition: 'background-color 0.3s ease',
            }}
            onClick={() => {
              setEditEventsVisible(true);
            }}
          >
            + Add Event
          </Paper>
        )}
      </Stack>
    </>
  );
};

export default EventStack;
