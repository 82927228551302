import React from "react";
import { useTheme } from "@mui/material/styles";
import Link from "@mui/material/Link";
import {
  Box,
  Button,
  ThemeProvider,
  TextField,
  InputLabel,
  Input,
  Alert,
  AlertTitle,
} from "@mui/material";
import bg from "../../assets/wave-haikei.svg";
import dark_bg from "../../assets/wave-haikei-dark.svg";
import AuthContext from "components/API/AuthContext";
import { theme, darkTheme } from "../../components/Theme/Theme";
import { useNavigate } from "react-router-dom";
import NavBar from "components/NavBar/NavBar";

const ResetPassword = () => {
  const backgroundImage =
    localStorage.getItem("theme") == "dark" ? dark_bg : bg;
  const { resetPassword } = React.useContext(AuthContext);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [alert, setAlert] = React.useState(null);
  const [uid, setUid] = React.useState("");
  const [token, setToken] = React.useState("");
  const navigate = useNavigate();
  const resetPass = async () => {
    try {
      const { data, status } = await resetPassword(
        uid,
        token,
        password,
        confirmPassword
      );
      if (status == 200) {
        setAlert(
          <Alert severity="success">
            <AlertTitle>{data.detail}</AlertTitle>
          </Alert>
        );
        setTimeout(() => {
          navigate("/Login", { state: { index: 0 } });
        }, 3000);
      }
    } catch (error) {
      console.log(JSON.stringify(error.response.data));
      setAlert(
        <Alert severity="error">
          <AlertTitle>{JSON.stringify(error.response.data)}</AlertTitle>
        </Alert>
      );
    }
  };

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const uid = searchParams.get("uid");
    const token = searchParams.get("token");
    setUid(uid);
    setToken(token);
  }, []);

  return (
    <ThemeProvider
      theme={localStorage.getItem("theme") == "dark" ? darkTheme : theme}
    >
      <NavBar></NavBar>
      <Box
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              width: 400,
              boxShadow: 5,
              p: 2,
              borderRadius: 2,
              height: 400,
              border: 2,
              borderColor: "primary.main",
            }}
          >
            <TextField
              sx={{ width: "100%", mt: 5 }}
              label="Password"
              variant="outlined"
              color="primary"
              required
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            ></TextField>
            <TextField
              sx={{ width: "100%", mt: 2 }}
              label="Confirm password"
              variant="outlined"
              color="primary"
              required
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              type="password"
            ></TextField>
            <Button
              sx={{ width: "100%", mt: 2, mb: 2 }}
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              onClick={() => {
                resetPass();
              }}
            >
              Reset Password
            </Button>
            {alert}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ResetPassword;
