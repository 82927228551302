import React, { useEffect } from 'react';
import SETTINGS from '../../../settings.json';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import axios from 'axios';
import { getToken } from '../../../auth';
import {
  Button,
  Box,
  IconButton,
  Container,
  Typography,
  Modal,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Stack,
  ThemeProvider,
  CssBaseline,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper,
  AppBar,
  Popover,
  Grid,
} from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Draggable from 'react-draggable';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CircleIcon from '@mui/icons-material/Circle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { theme, darkTheme } from '../../../components/Theme/Theme';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import EventIcon from '@mui/icons-material/Event';
import DescriptionIcon from '@mui/icons-material/Description';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PaperComponent from './PaperComponent';

const url = SETTINGS.backend_url;

function CustomDetailModal({
  handleClose,
  isOpen = false,
  title = 'event',
  desc = 'event description here',
  startDate = '2024-07-18T00:00:00',
  endDate = '2024-07-18T00:00:00',
  onDeleteClick,
  onEditClick,
  id,
  calendarId,
  isCompleted,
  onMarkComplete,
  calendars,
  isAllDay,
  posx,
  posy,
}) {
  const [open, setOpen] = React.useState(false);
  const [dayString, setDayString] = React.useState('');
  const [calendarName, setCalendarName] = React.useState('');
  const [calendarColor, setCalendarColor] = React.useState('');
  const [notifications, setNotifications] = React.useState(false);
  const [eventNotification, setEventNotification] = React.useState([]); 
  const addNotification = () => {
    setEventNotification((prev) => [
    ...prev,
    {id: Math.random() }, // Each notification gets a unique ID
  ]);
};
const removeNotification = (id) =>{
  setEventNotification(eventNotification.filter((notification) => notification.id !==id));
}
  async function enableNotifications() {
    const { data, status } = await axios.post(
      url + 'enableEventNotification',
      { eventID: id, enable: !notifications },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: await getToken(),
        },
      },
    );
    setNotifications(!notifications);
  }

  async function getNotifications() {
    const { data, status } = await axios.get(
      url + 'EventNotification?eventID=' + id,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: await getToken(),
        },
      },
    );
    return data['enabled'];
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    (async () => {
      if (id) {
        setNotifications(await getNotifications());
      }
    })();
  }, [id]);

  useEffect(() => {
    if (dayjs(startDate).isSame(dayjs(endDate), 'day')) {
      if (isAllDay) {
        setDayString(dayjs(startDate).format('ddd MMM, D'));
      } else {
        setDayString(
          dayjs(startDate).format('ddd MMM, D') +
            ' ' +
            dayjs(startDate).format('h:mm A') +
            ' - ' +
            dayjs(endDate).format('h:mm A'),
        );
      }
    } else {
      if (isAllDay) {
        setDayString(
          dayjs(startDate).format('ddd MMM, D') +
            ' - ' +
            dayjs(endDate).format('ddd MMM, D'),
        );
      } else {
        setDayString(
          dayjs(startDate).format('ddd MMM, D') +
            ' ' +
            dayjs(startDate).format('h:mm A') +
            ' - ' +
            dayjs(endDate).format('ddd MMM, D') +
            ' ' +
            dayjs(endDate).format('h:mm A'),
        );
      }
    }

    const cal = calendars.find((cal) => cal.id === calendarId);
    if (cal) {
      setCalendarName(cal.name);
      setCalendarColor(cal.backgroundColor);
    }
  }, [startDate, endDate, calendarId, calendars]);

  return (
    <ThemeProvider
      theme={localStorage.getItem('theme') === 'dark' ? darkTheme : theme}
    >
      <CssBaseline />
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          },
          '@media (min-width: 600px)': {
            '& .MuiDialog-container': {
              top: `${posy - 275}px`,
              left: `${posx - 450}px`,
              transform: 'none',
            },
          },
        }}
        open={isOpen}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        hideBackdrop={true}
        fullWidth={true}
        maxWidth={'xs'}
      >
        <AppBar position="static">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div>
              <IconButton
                size="small"
                edge="start"
                color="inherit"
                aria-label="menu"
                disableRipple={true}
                sx={{ ml: 1 }}
                style={{ cursor: 'move' }} // adjust height as needed
                id="draggable-dialog-title"
              >
                <DragHandleIcon fontSize="small" />
              </IconButton>
            </div>

            <div style={{ display: 'flex' }}>
              <IconButton
                color="inherit"
                size="small"
                disableRipple={true}
                onClick={onEditClick}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton
                color="inherit"
                size="small"
                disableRipple={true}
                onClick={() => {
                  onDeleteClick();
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>

              <IconButton
                color="inherit"
                size="small"
                disableRipple={true}
                onClick={() => {
                  handleClose();
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
        </AppBar>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={1}>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <Typography variant="h6" gutterBottom>
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <DescriptionIcon />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body1" gutterBottom>
                  <div dangerouslySetInnerHTML={{ __html: desc }} />
                </Typography>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <ScheduleIcon />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body2" gutterBottom>
                  {dayString}
                </Typography>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <EventIcon />
              </Grid>
              <Grid item xs={1}>
                <CircleIcon
                  fontSize="small"
                  sx={{
                    color: calendarColor,
                    '&.Mui-checked': {
                      color: calendarColor,
                    },
                  }}
                ></CircleIcon>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2" gutterBottom>
                  {calendarName}
                </Typography>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      {eventNotification.map((notification) => (
      <Box style={{ display: 'flex', alignItems: 'center', margin:'5px',paddingLeft:'20px'}} id={notification.id}>
  <FormControl variant="outlined" sx={{ width:"140px" }}>
    <TextField
      label="Time"
      type="number"
      InputLabelProps={{ shrink: true }}
      size="small"  // Optional: Adjusts the size of the input for better alignment
    />
  </FormControl>

  {/* Time Unit Dropdown */}
  <FormControl variant="outlined" style={{width:'180px', margin:"5px"}}>  {/* Increased minWidth */}
    <InputLabel id="time-unit-label">Unit</InputLabel>
    <Select
      labelId="time-unit-label"
      label="Unit"
      size="small"  // Optional: Adjusts the size of the dropdown
    >
      <MenuItem value="minutes">minutes</MenuItem>
      <MenuItem value="hours">hours</MenuItem>
      <MenuItem value="days">days</MenuItem>
    </Select>
  </FormControl>

  {/* Close Button */}
  <IconButton color="default">
    <CloseIcon onClick= {()=> removeNotification(notification.id)} />
  </IconButton>
</Box>
      ))}
      <Typography variant="body1" sx={{margin:'5px', paddingLeft:"20px"}}>Notification</Typography>
      <Button variant="contained" onClick={addNotification} sx={{ mb: 2,width:"350px",marginLeft:"20px" }}>
        Add Notification
      </Button>
        <DialogActions>
          {isCompleted ? (
            <Button
              onClick={() => {
                const event = {
                  id: id,
                  calendarId: calendarId,
                  title: title,
                  start: startDate,
                  end: endDate,
                  isCompleted: false,
                  body: desc,
                };
                onMarkComplete(event);
              }}
            >
              Mark Uncomplete
            </Button>
          ) : (
            <Button
              onClick={() => {
                const event = {
                  id: id,
                  calendarId: calendarId,
                  title: title,
                  start: startDate,
                  end: endDate,
                  isCompleted: true,
                  body: desc,
                };
                onMarkComplete(event);
              }}
            >
              Mark Complete
            </Button>
          )}
          <Button
            title="Enable text notifications"
            onClick={enableNotifications}
          >

          </Button>
          {startDate && endDate && (
            <add-to-calendar-button
              name={title}
              startDate={startDate.split('T')[0]}
              startTime={startDate.split('T')[1].substring(0, 5)}
              endTime={endDate.split('T')[1].substring(0, 5)}
              endDate={endDate.split('T')[0]}
              timeZone="America/Los_Angeles"
              description={desc}
              options="'Apple','Google','Outlook.com'"
              buttonsList
              hideTextLabelButton
              buttonStyle="round"
              lightMode="bodyScheme"
            ></add-to-calendar-button>
          )}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default CustomDetailModal;
