import React from 'react';
import { Amplify } from 'aws-amplify';
import { Link } from 'react-router-dom';

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

export default function Login() {
  return (
    <div style={{ marginTop: 100, marginBottom: 100 }}>
      <Authenticator
        socialProviders={['google']}
        signUpAttributes={['email', 'name', 'phone_number']}
      >
        {({ signOut, user }) => (
          <main>
            <h1>Hello {user.username}</h1>
            <Link to="/Calendar">My Calendar</Link>
            <br />
            <button onClick={signOut}>Sign out</button>
          </main>
        )}
      </Authenticator>
    </div>
  );
}
