import React from 'react';
import { Box } from '@mui/material';

function Calendar({ children }) {
  return (
    <Box>
      <main>{children}</main>
    </Box>
  );
}

export default Calendar;
