import React from "react";
import { useTheme } from "@mui/material/styles";
import Link from "@mui/material/Link";
import {
  Box,
  Button,
  ThemeProvider,
  TextField,
  InputLabel,
  Input,
  Alert,
  AlertTitle,
} from "@mui/material";
import bg from "../../assets/wave-haikei.svg";
import dark_bg from "../../assets/wave-haikei-dark.svg";
import AuthContext from "components/API/AuthContext";
import NavBar from "components/NavBar/NavBar";
import { theme, darkTheme } from "../../components/Theme/Theme";

const ForgotPassword = () => {
  const backgroundImage =
    localStorage.getItem("theme") == "dark" ? dark_bg : bg;
  const { forgotPassword } = React.useContext(AuthContext);
  const [email, setEmail] = React.useState("");
  const [alert, setAlert] = React.useState(null);
  const forgotPass = async () => {
    const { data, status } = await forgotPassword(email);
    setEmail("");
    if (status == 200) {
      console.log(data);
      setAlert(
        <Alert severity="success">
          <AlertTitle>{data.detail}</AlertTitle>
        </Alert>
      );
    } else {
      setAlert(
        <Alert severity="error">
          <AlertTitle>{data.detail}</AlertTitle>
        </Alert>
      );
    }
  };

  return (
    <ThemeProvider
      theme={localStorage.getItem("theme") == "dark" ? darkTheme : theme}
    >
      <NavBar></NavBar>
      <Box
        style={{
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              width: 400,
              boxShadow: 5,
              p: 2,
              borderRadius: 2,
              height: 270,
              border: 2,
              borderColor: "primary.main",
            }}
          >
            <TextField
              sx={{ width: "100%", mt: 5 }}
              label="Email"
              variant="outlined"
              color="primary"
              required
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            ></TextField>
            <Button
              sx={{ width: "100%", mt: 2, mb: 2 }}
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              onClick={() => forgotPass(email)}
            >
              Reset Password
            </Button>
            {alert}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ForgotPassword;
