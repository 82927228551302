import React from 'react';
import styled from 'styled-components';
import { Document, Page, pdfjs, PDFViewer as PDFV } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import EventStack from './EventStack';
import { Grid } from '@mui/material';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
const url = 'http://localhost:8000/media/';
const PDFViewer = ({
  fileURL,
  setSearchFunction,
  calendar_id,
  eventsArray,
  searchFunction,
}) => {
  const [numPages, setNumPages] = React.useState(0);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const pdfRef = React.createRef();
  // console.log(url + fileURL);

  /*  const PDFDocumentWrapper = styled.div`
    canvas {
      width: 100% !important;
      height: auto !important;
    }
  `;
*/

  React.useEffect(() => {
    setSearchFunction(() => (page, searchText) => {
      if (document.getElementById('pdfjs-frame-' + page) == null) return;
      const iframeDocument = document.getElementById('pdfjs-frame-' + page)
        .contentWindow.document;
      //PDFJS adds a space in front of the number
      //Ex: 1st ->  1 st
      //This regex adds the space
      let r = /\b\d+(?:st|nd|rd|th)\b/g;
      let part = searchText.match(r);
      if (part) {
        part = part[0].replace(/\s?st|nd|rd|th/, '');
        searchText = searchText.replace(r, part);
      }
      // Highlight "test"
      const highlightAll = (node) => {
        console.log(node, node.textContent.includes(searchText));
        if (node.textContent.includes(searchText)) {
          node.className += ' highlight';
          node.childNodes.forEach((node) => (node.className += ' highlight'));
        } else {
          node.childNodes.forEach(highlightAll);
        }
      };

      const unhighlightAll = (node) => {
        if (node && node.className && node.className.includes('highlight')) {
          node.className = node.className.replace('highlight', '');
        } else {
          node.childNodes.forEach(unhighlightAll);
        }
      };

      if (
        iframeDocument !== null &&
        iframeDocument.querySelector('.textLayer')
      ) {
        let node = iframeDocument.querySelector('.textLayer');
        unhighlightAll(node);
        iframeDocument
          .querySelector('.textLayer')
          .childNodes.forEach((node) => {
            highlightAll(node);
          });
      }
    });
  }, []);
  function resizeIframe(iframe) {
    const checkForPageElement = () => {
      const pageElement =
        iframe.target.contentWindow.document.querySelector('.page');

      if (pageElement && pageElement.scrollHeight > 500) {
        clearInterval(intervalId);
        iframe.target.style.height =
          iframe.target.contentWindow.document.body.scrollHeight + 50 + 'px';
      }
    };

    const intervalId = setInterval(checkForPageElement, 500);
  }
  return (
    <>
      <Document file={fileURL} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Grid container spacing={2}>
            <Grid item xs={8} md={8}>
              <div
                style={{
                  scrollbarWidth: 'thin',
                  // To give it a card look
                  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)', // Horizontal offset, vertical offset, blur radius, shadow color
                  borderRadius: '8px', // This makes the corners rounded to enhance the card look
                  backgroundColor: '#ffffff',
                  marginBottom: '10px',
                }}
              >
                {/* <PDFV
              renderTextLayer={true}
              key={`page_${index + 1}`}
              width={'700'}
              pageNumber={index + 1}
            /> */}
                <iframe
                  src={
                    './pdfjs/web/pageviewer.html?file=' +
                    encodeURIComponent(fileURL) +
                    '&page=' +
                    (index + 1)
                  }
                  id={'pdfjs-frame-' + (index + 1)}
                  ref={pdfRef}
                  onLoad={resizeIframe}
                  style={{
                    width: '100%',
                    height: '100vh',
                    border: 'none',
                    overflow: 'hidden',
                  }}
                  scrolling="no"
                ></iframe>
              </div>
            </Grid>
            <Grid item xs={4} md={4}>
              <EventStack
                key={'page' + index + 1 + ' ' + eventsArray.length}
                eventsArray={eventsArray.filter((e) => e.page === index + 1)}
                searchFunction={searchFunction}
                cal={calendar_id}
              />
            </Grid>
          </Grid>
        ))}
      </Document>
    </>
  );
};

export default PDFViewer;
