import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Grid,
  Button,
  Alert,
  AlertTitle,
  Box,
  Typography,
  Container,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createTheme } from "@mui/material/styles";

const Privacy = () => {
  return (
    <>
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" gutterBottom>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You. We use Your Personal data to provide and improve the
          Service. By using the Service, You agree to the collection and use of
          information in accordance with this Privacy Policy. This Privacy
          Policy has been created with the help of the Free Privacy Policy
          Generator.
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          Interpretation and Definitions{" "}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <Typography variant="h6" gutterBottom>
            Interpretation
          </Typography>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
          <Typography variant="h6" gutterBottom>
            Definitions
          </Typography>
          For the purposes of this Privacy Policy:
          <br></br>
          <b>Account</b> means a unique account created for You to access our
          Service or parts of our Service.
          <br></br>
          <b>Affiliate</b> means an entity that controls, is controlled by or is
          under common control with a party, where "control" means ownership of
          50% or more of the shares, equity interest or other securities
          entitled to vote for election of directors or other managing
          authority.
          <br></br>
          <b>Company</b> (referred to as either "the Company", "We", "Us" or
          "Our" in this Agreement) refers to Open Planner LLC, 2800 W Bewick St
          Fort Worth, TX 78737.
          <br></br>
          <b>Cookies</b> are small files that are placed on Your computer,
          mobile computer, mobile device or any other device by a website,
          containing the details of Your browsing history on that website among
          its many uses.
          <br></br>
          <b>Country</b> refers to: Texas, United States
          <br></br>
          <b>Device</b> means any device that can access the Service such as a
          computer, a cellphone or a digital tablet.
          <br></br>
          <b>Personal Data</b> is any information that relates to an identified
          or identifiable individual.
          <br></br>
          <b>Service</b> refers to the Website.
          <br></br>
          <b>Service Provider</b> means any natural or legal person who
          processes the data on behalf of the Company. It refers to third-party
          companies or individuals employed by the Company to facilitate the
          Service, to provide the Service on behalf of the Company, to perform
          services related to the Service or to assist the Company in analyzing
          how the Service is used.
          <br></br>
          <b>Usage Data</b> refers to data collected automatically, either
          generated by the use of the Service or from the Service infrastructure
          itself (for example, the duration of a page visit).
          <br></br>
          <b>Website</b> refers to Open Planner, accessible from
          open-planner.com
          <br></br>
          <b>You</b> means the individual accessing or using the Service, or the
          company, or other legal entity on behalf of which such individual is
          accessing or using the Service, as applicable.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          Collection of Personal Information
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may collect personal information from you when you voluntarily
          provide it to us. The types of personal information we may collect
          include:
          <ul>
            <li>Full name</li>
            <li>Email address</li>
          </ul>
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          Use of Personal Information
        </Typography>
        <Typography variant="body1" gutterBottom>
          We will use the personal information collected from you solely for the
          purpose of providing the services you requested or for other
          legitimate business purposes. The specific purposes for which we may
          use your personal information include:
          <ul>
            <li>Communicating with you regarding your inquiries or requests</li>
            <li>
              Sending you information related to our services, updates, and
              promotional offers
            </li>
            <li>Managing and improving our website and services</li>
            <li>
              Complying with applicable laws, regulations, and legal processes
            </li>
          </ul>
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          Disclosure of Personal Information
        </Typography>
        <Typography variant="body1" gutterBottom>
          We do not sell, trade, or transfer your personal information to third
          parties unless we have obtained your consent or are required by law to
          do so. However, we may disclose your personal information to trusted
          third-party service providers who assist us in operating our website,
          conducting our business, or providing services to you. These third
          parties are obligated to keep your personal information confidential
          and are not permitted to use it for any other purpose.
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          Security
        </Typography>
        <Typography variant="body1" gutterBottom>
          We take reasonable measures to protect the personal information you
          provide to us against unauthorized access, alteration, disclosure, or
          destruction. However, please note that no method of transmission over
          the internet or electronic storage is 100% secure, and we cannot
          guarantee absolute security.
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          Cookies and Tracking Technologies
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may use cookies and other tracking technologies to enhance your
          experience on our website. These technologies may collect non-personal
          information such as your IP address, browser type, and device
          information. You have the option to disable cookies in your browser
          settings, but please note that this may affect the functionality of
          our website.
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          Children's Privacy
        </Typography>
        <Typography variant="body1" gutterBottom>
          Our website is not intended for individuals under the age of 18. We do
          not knowingly collect personal information from children. If we become
          aware that we have collected personal information from a child under
          the age of 18, we will take steps to delete the information as soon as
          possible.
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          Third-Party Links
        </Typography>
        <Typography variant="body1" gutterBottom>
          Our website may contain links to third-party websites. We are not
          responsible for the privacy practices or content of these websites. We
          encourage you to review the privacy policies of those websites before
          providing any personal information.
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          Updates to this Privacy Policy
        </Typography>
        <Typography variant="body1" gutterBottom>
          We reserve the right to update or modify this Privacy Policy at any
          time without prior notice. Any changes to the policy will be posted on
          our website with the updated effective date. We encourage you to
          review this Privacy Policy periodically to stay informed about how we
          are protecting your personal information.
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          Contact Us
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you have any questions, concerns, or requests regarding this
          Privacy Policy or the handling of your personal information, please
          contact us at{" "}
          <Link href="mailto:openplanner3@gmail.com">
            openplanner3@gmail.com
          </Link>
          .
        </Typography>
        <Typography variant="body1" sx={{ mt: 3 }}>
          By using our website, you acknowledge that you have read and
          understood this Privacy Policy and agree to the collection, use, and
          disclosure of your personal information as described herein.
        </Typography>
        <Typography variant="body1" sx={{ mt: 3 }}>
          Open Planner LLC
          <br />
          2800 W Bewick St
          <br />
          Fort Worth, TX, 76109
          <br />
          Phone: 425-301-9169
          <br />
          Email: openplanner3@gmail.com
        </Typography>
      </Container>
    </>
  );
};

export default Privacy;
