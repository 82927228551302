import React, { useCallback, useEffect, useState, useRef } from 'react';
import { updateUserEvent, deleteUserEvent } from 'components/API/EventAPI';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';

import {
  Typography,
  Card,
  IconButton,
  CardHeader,
  CardActions,
  CardContent,
  TextField,
  Container,
  Grid,
  Button,
  Popover,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DigitalClock } from '@mui/x-date-pickers/DigitalClock';
import dayjs from 'dayjs';
import { DateCalendar } from '@mui/x-date-pickers';

function EventDescription(props) {
  const [showAll, setShowAll] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const divRef = useRef(null);
  useEffect(() => {
    if (divRef.current.offsetHeight > 100) {
      setShowButton(true);
    }
  }, [props.text]);

  useEffect(() => {
    const divStyle = showAll
      ? { maxHeight: '', overflow: 'visible' }
      : { maxHeight: '100px', overflow: 'hidden' };

    if (showButton) {
      Object.assign(divRef.current.style, divStyle);
    }
  }, [showButton, showAll]);

  return (
    <div>
      <div ref={divRef} dangerouslySetInnerHTML={{ __html: props.text }} />
      {showButton && (
        <>
          <a
            style={{
              textDecoration: 'underline',
            }}
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? 'Hide All' : 'Show All'}
          </a>
        </>
      )}
    </div>
  );
}
const EventCard = ({ even, searchFunction }) => {
  const [editing, setEditing] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [title, settitle] = useState('');
  const [body, setBody] = useState('');
  const [category, setcategory] = useState('');
  const handleSave = (e) => {
    even['title'] = title;
    even['start'] =
      dayjs(startDateIn).format('YYYY-MM-DD') +
      'T' +
      dayjs(startTime, 'hh:mm A').format('HH:mm:ss');
    even['end'] =
      dayjs(endDateIn).format('YYYY-MM-DD') +
      'T' +
      dayjs(endTime, 'hh:mm A').format('HH:mm:ss');
    even['category'] = category;
    even['body'] = body;
    updateUserEvent(even);
    setEditing(false);
  };
  const handleDelete = (e) => {
    console.log(even);
    deleteUserEvent(even['id']);
    setDeleted(true);
  };

  const [startDateIn, setStartDateIn] = React.useState();
  const [endDateIn, setEndDateIn] = React.useState();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElTo, setAnchorElTo] = React.useState(null);
  const [startTime, setStartTime] = React.useState('12:00 AM');
  const [endTime, setEndTime] = React.useState('12:30 AM');
  const [date, setDate] = React.useState();
  const [dateEnd, setDateEnd] = React.useState();
  const [anchorElDate, setAnchorElDate] = React.useState(null);
  const [anchorElDateEnd, setAnchorElDateEnd] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClosePop = () => {
    setAnchorEl(null);
  };
  const handleClickTo = (event) => {
    setAnchorElTo(anchorElTo ? null : event.currentTarget);
  };
  const handleClosePopTo = () => {
    setAnchorElTo(null);
  };
  const handleClickDate = (event) => {
    setAnchorElDate(anchorElDate ? null : event.currentTarget);
  };

  const handleClickDateEnd = (event) => {
    setAnchorElDateEnd(anchorElDateEnd ? null : event.currentTarget);
  };
  const handleClosePopDate = () => {
    setAnchorElDate(null);
  };

  const handleClosePopDateEnd = () => {
    setAnchorElDateEnd(null);
  };

  const handleCalendarClick = (event) => {
    const date = dayjs(event.$d);
    setStartDateIn(date.format('YYYY-MM-DDTHH:mm:ss'));
    const formattedDate = date.format('ddd, MMMM DD');
    setDate(formattedDate);
  };

  const handleCalendarClickEnd = (event) => {
    const date = dayjs(event.$d);
    setEndDateIn(date.format('YYYY-MM-DDTHH:mm:ss'));
    const formattedDate = date.format('ddd, MMMM DD');
    setDateEnd(formattedDate);
  };

  const handleTitleChange = (e) => {
    settitle(e.target.value);
  };

  const handleBodyChange = (e) => {
    setBody(e.target.value);
  };
  const quilBodyChange = (e) =>{
    setBody(e)
  }
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const openTo = Boolean(anchorElTo);
  const idTo = openTo ? 'simple-popper-to' : undefined;
  const openDate = Boolean(anchorElDate);
  const openDateEnd = Boolean(anchorElDateEnd);
  const idDate = openDate ? 'simple-popper-date' : undefined;
  const idDateEnd = openDateEnd ? 'simple-popper-date-end' : undefined;
  useEffect(() => {
    settitle(even['title']);
    setBody(even['body']);
    setStartDateIn(dayjs(even['start']).format('YYYY-MM-DDTHH:mm:ss'));
    setEndDateIn(dayjs(even['end']).format('YYYY-MM-DDTHH:mm:ss'));

    setDate(dayjs(even['start']).format('ddd, MMM DD'));
    setDateEnd(dayjs(even['end']).format('ddd, MMM DD'));

    setStartTime(dayjs(even['start']).format('hh:mm A'));
    setEndTime(dayjs(even['end']).format('hh:mm A'));

    setDate(dayjs(even['start']));
    setDateEnd(dayjs(even['end']));

    setcategory(even['category']);
  }, []);

  return deleted ? (
    <></>
  ) : editing ? (
    <Card key={even['id']} sx={{ m: 2 }}>
      <CardContent>
        <Typography variant="subtitle2">
          Title:
          <ReactQuill
          id="title"
          value={body.replaceAll('<b>', '<br/><b>')
            .replace(/^<br\/>/, '')}
          theme="bubble"  // Use "bubble" theme for a simple text box
          modules={{ toolbar: false }}  // Disable toolbar
          formats={[
            'bold', 'italic', 'underline', 'strike',  // Basic text formatting options
            'header', 'list', 'bullet', 'blockquote',  // Other format types
          ]}
          placeholder="Write something formatted..."  // Optional placeholder
          onChange={quilBodyChange}
        />
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          Start:
          <Grid container>
            <Grid item xs={6}>
              <TextField
                id="name"
                label="Date"
                value={dayjs(date).format('ddd, MMMM DD')}
                type="text"
                fullWidth
                size="small"
                variant="standard"
                onClick={(event) => {
                  handleClickDate(event);
                }}
              />
              <Popover
                id={idDate}
                open={openDate}
                anchorEl={anchorElDate}
                onClose={handleClosePopDate}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    value={dayjs(startDateIn)}
                    onChange={(newValue) => {
                      handleCalendarClick(newValue);
                      handleClosePopDate();
                    }}
                  />
                </LocalizationProvider>
              </Popover>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="name"
                label="From"
                fullWidth
                size="small"
                variant="standard"
                onClick={(event) => {
                  handleClick(event);
                }}
                value={startTime}
                InputProps={{
                  style: { caretColor: 'transparent' },
                }}
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClosePop}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DigitalClock
                    // value={startTime}
                    onChange={(event) => {
                      let formattedDate = dayjs(event.$d).format('h:mm A');
                      setStartTime(formattedDate);
                      let formattedEndTime = dayjs(event.$d)
                        .add(30, 'minute')
                        .format('h:mm A');
                      setEndTime(formattedEndTime);
                      handleClosePop();
                    }}
                  />
                </LocalizationProvider>
              </Popover>
            </Grid>
          </Grid>
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          End:
          <Grid container>
            <Grid item xs={6}>
              <TextField
                id="name"
                label="Date"
                value={dayjs(dateEnd).format('ddd, MMMM DD')}
                type="text"
                fullWidth
                size="small"
                variant="standard"
                onClick={(event) => {
                  handleClickDateEnd(event);
                }}
              />
              <Popover
                id={idDateEnd}
                open={openDateEnd}
                anchorEl={anchorElDateEnd}
                onClose={handleClosePopDateEnd}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    value={dayjs(endDateIn)}
                    onChange={(newValue) => {
                      handleCalendarClickEnd(newValue);
                      handleClosePopDateEnd();
                    }}
                  />
                </LocalizationProvider>
              </Popover>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="name"
                label="To"
                fullWidth
                size="small"
                variant="standard"
                onClick={(event) => {
                  handleClickTo(event);
                }}
                value={endTime}
                InputProps={{
                  style: { caretColor: 'transparent' },
                }}
              />
              <Popover
                id={idTo}
                open={openTo}
                anchorEl={anchorElTo}
                onClose={handleClosePopTo}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DigitalClock
                    // value={endTime}
                    onChange={(event) => {
                      let formattedDate = dayjs(event.$d).format('h:mm A');
                      setEndTime(formattedDate);
                      handleClosePopTo();
                    }}
                  />
                </LocalizationProvider>
              </Popover>
            </Grid>
          </Grid>
        </Typography>
        {/* <Typography variant="body2" sx={{mt:2}}>
          Category:
          <TextField
            autoFocus
            id="category"
            type="text"
            fullWidth
            variant="standard"
            value={category}
            onChange={handleCategoryChange}
          />
        </Typography> */}
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleSave}>
          Save
        </Button>
      </CardActions>
    </Card>
  ) : (
    <Card
      key={even['id']}
      sx={{
        m: 2,
        '&:hover': {
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        },
      }}
      onClick={() => {
        searchFunction(even['page'], even['title']);
      }}
    >
      <CardHeader
        subheader={
          <Grid container spacing74={2} alignItems="center">
            <Grid
              item
              xs={9}
              md={9}
              style={{ color: 'white', fontWeight: 'bold' }}
            >
              {new Date(even['start']).toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                timeZone: 'UTC',
              })}
              &nbsp;{' '}
              <small style={{ fontSize: 10, fontWeight: 'normal' }}>
                {dayjs(even['start']).format('dddd')}
              </small>
            </Grid>
            <Grid item xs={1} md={1}>
              <IconButton onClick={() => setEditing(true)}>
                <EditIcon
                  sx={{
                    color: 'white',
                  }}
                />
              </IconButton>
            </Grid>
            <Grid item xs={1} md={1}>
              <IconButton onClick={handleDelete}>
                <DeleteIcon
                  sx={{
                    color: 'white',
                  }}
                />
              </IconButton>
            </Grid>
          </Grid>
        }
        sx={{ bgcolor: '#b14bf8', height: '30px' }}
      />
     
      <CardContent
        sx={{
          padding: '10px',
          paddingBottom: '10px !important',
        }}
      >
        <Typography variant="subtitle2">
          <EventDescription
            text={even.body
              .replaceAll('<b>', '<br/><b>')
              .replace(/^<br\/>/, '')}
          />
        </Typography>
        {/* <Typography variant="body2">
          <b>Category</b>: {even["category"]}
        </Typography> */}
      </CardContent>
    </Card>
  );
};

export default EventCard;
