import { createContext, useState, useEffect } from 'react';
import { getToken } from '../../auth.js';
import SETTINGS from '../../settings.json';

import axios from 'axios';
const url = SETTINGS.backend_url;
//const url = 'http://localhost:8000/';

function getFormattedTime(date) {
  const formatted = new Date(date);
  const year = formatted.getFullYear();
  const month = String(formatted.getMonth() + 1).padStart(2, '0');
  const day = String(formatted.getDate()).padStart(2, '0');
  const hour = String(formatted.getHours()).padStart(2, '0');
  const minute = String(formatted.getMinutes()).padStart(2, '0');
  const second = String(formatted.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
}

const getAllUserEvents = async () => {
  try {
    const { data, status } = await axios.get(url + 'eventsByUser', {
      headers: {
        Authorization: await getToken(),
      },
    });
    console.log('response status is: ', status);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message);
      return error.message;
    } else {
      console.log('unexpected error: ', error);
      return 'An unexpected error occurred';
    }
  }
};

const getUserEventsByCalendar = async (calendar_id) => {
  try {
    const { data, status } = await axios.get(
      url + `events?syllabusID=${calendar_id}`,
      {
        headers: {
          Authorization: await getToken(),
        },
      },
    );
    console.log('response status is: ', status);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message);
      return error.message;
    } else {
      console.log('unexpected error: ', error);
      return 'An unexpected error occurred';
    }
  }
};

const deleteUserEvent = async (eId) => {
  try {
    const { data, status } = await axios.delete(url + 'event?eventID=' + eId, {
      headers: {
        Authorization: await getToken(),
      },
    });
    console.log('response status is: ', status);
    console.log(JSON.stringify(data));
    return 'Success';
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message);
      return error.message;
    } else {
      console.log('unexpected error: ', error);
      return 'An unexpected error occurred';
    }
  }
};

const updateUserEvent = async (event) => {
  console.log('event is: ', event);
  event.event_id = event.id;
  event.syllabusID = event.calendar;
  try {
    event.start = getFormattedTime(event.start);
    event.end = getFormattedTime(event.end);
    const { data, status } = await axios.patch(url + 'event', event, {
      headers: {
        Authorization: await getToken(),
        'Content-Type': 'application/json',
      },
    });
    console.log('response status is: ', status);
    console.log(JSON.stringify(data));
    return 'Success';
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error);
      console.log('error message: ', error.message);
      return error.message;
    } else {
      console.log('unexpected error: ', error);
      return 'An unexpected error occurred';
    }
  }
};

const addUserEvent = async (event) => {
  try {
    event.start = getFormattedTime(event.start);
    event.end = getFormattedTime(event.end);
    event.syllabusID = event.calendar;
    const { data, status } = await axios.post(url + 'newEvent/', event, {
      headers: {
        Authorization: await getToken(),
      },
    });
    console.log('response status is: ', status);
    return { data, message: 'Success' };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message);
      return { message: error.message };
    } else {
      console.log('unexpected error: ', error);
      return { message: 'An unexpected error occurred' };
    }
  }
};

const updateAllEvents = async (events, time) => {
  try {
    const { data, status } = await axios.post(
      url + 'update-all-events/',
      {
        events: events,
        time: time,
      },
      {
        headers: {
          Authorization: localStorage.getItem('authToken'),
        },
      },
    );
    console.log('response status is: ', status);
    return { message: 'Success' };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message);
      return { message: error.message };
    } else {
      console.log('unexpected error: ', error);
      return { message: 'An unexpected error occurred' };
    }
  }
};
export {
  addUserEvent,
  updateUserEvent,
  deleteUserEvent,
  getAllUserEvents,
  getUserEventsByCalendar,
  updateAllEvents,
};
