import React, { useCallback, useEffect, useState, useReducer } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { isLoggedIn } from '../../auth.js';
import PDFViewer from './components/PDFViewer';
import { patchCalendar, putCalendar } from 'components/API/CalendarAPI';
import EventStack from './components/EventStack';
import { getUserCalendar } from 'components/API/CalendarAPI';
import { getSyllabus } from 'components/API/SyllabusAPI';
import {
  getAllUserEvents,
  getUserEventsByCalendar,
  updateAllEvents,
} from 'components/API/EventAPI';
import {
  Typography,
  Card,
  CardActions,
  CardContent,
  TextField,
  Container,
  Grid,
  Button,
  MenuItem,
  Select,
  Popover,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import colors from 'pages/CalendarPage/globalCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DigitalClock } from '@mui/x-date-pickers/DigitalClock';
import dayjs from 'dayjs';

function Syllabus() {
  const navigate = useNavigate();
  const location = useLocation();
  const [fileURL, setFileURL] = useState('');
  const [meta, setMeta] = useState({});
  const [calendar, setCalendar] = useState('');
  const [searchFunction, setSearchFunction] = useState(undefined);
  const [class_name, setclass_name] = useState('');
  const [professor_name, setprofessor_name] = useState('');
  const [class_location, setclass_location] = useState('');
  const [class_time, setclass_time] = useState('');
  const [color, setcolor] = useState('');

  const [eventStack, setEventStack] = useState('');
  const [eventsArray, setEventsArray] = useState([]);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const handleClassNameChange = (e) => {
    setclass_name(e.target.value);
    meta['class_name'] = e.target.value;
  };
  const handleProfessorNameChange = (e) => {
    setprofessor_name(e.target.value);
    meta['professor_name'] = e.target.value;
  };
  const handleClassLocationChange = (e) => {
    setclass_location(e.target.value);
    meta['class_location'] = e.target.value;
  };
  const handleClassTimeChange = (e) => {
    setStartTime(e.target.value);
    meta['class_time'] = e.target.value;
  };
  const handleColorChange = (e) => {
    setcolor(e.target.value);
    calendar['color'] = e.target.value;
  };

  const handleSave = (e) => {
    meta['class_name'] = class_name;
    meta['professor_name'] = professor_name;
    meta['class_location'] = class_location;
    meta['class_time'] = startTime;
    putCalendar(calendar);
    //setEventStack();
    //forceUpdate();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [startTime, setStartTime] = React.useState('12:00 AM');

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClosePop = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const searchParams = new URLSearchParams(location.search);
  const calendar_id = searchParams.get('calendar')
    ? searchParams.get('calendar')
    : [];
  useEffect(() => {
    if (isLoggedIn()) {
      const syllabus_id = searchParams.get('id') ? searchParams.get('id') : [];
      const calendar_id = searchParams.get('calendar')
        ? searchParams.get('calendar')
        : [];

      getSyllabus(syllabus_id).then((response) => {
        setFileURL(response);
      });
      getUserCalendar(calendar_id).then((response) => {
        setCalendar(response);
        setMeta(response['meta']);
        setclass_name(response['meta']['class_name']);
        setprofessor_name(response['meta']['professor_name']);
        setclass_location(response['meta']['class_location']);
        setStartTime(
          response['meta']['class_time'].split('-')[0].trim().toUpperCase(),
        );
        setcolor(response['color']);
        console.log('syllabus', searchFunction);
        getUserEventsByCalendar(calendar_id).then((response) => {
          setEventsArray(response.slice());
          setEventStack(
            <EventStack
              key={calendar_id}
              eventsArray={response}
              searchFunction={searchFunction}
              cal={calendar_id}
            />,
          );
        });
      });
    }
  }, [ignored, searchFunction]);
  return (
    <>
      <Container maxWidth="sm">
        <Card
          raised={true}
          sx={{
            minWidth: 275,
            maxWidth: 400,
            borderColor: calendar['color'],
            m: 5,
          }}
          key={fileURL}
        >
          <CardContent>
            <Typography variant="body2" color={calendar['color']}>
              <b> Class Name: </b>
              <TextField
                autoFocus
                id="class_name"
                type="text"
                fullWidth
                variant="standard"
                value={class_name}
                onChange={handleClassNameChange}
              />
              {/* {meta['class_name']} */}
            </Typography>
            <Typography variant="body2" color={calendar['color']}>
              <b> Professor Name: </b>
              <TextField
                autoFocus
                id="professor_name"
                type="text"
                fullWidth
                variant="standard"
                value={professor_name}
                onChange={handleProfessorNameChange}
              />
              {/* {meta['professor_name']} */}
            </Typography>
            <Typography variant="body2" color={calendar['color']}>
              <b> Class Location: </b>
              <TextField
                autoFocus
                id="class_location"
                type="text"
                fullWidth
                variant="standard"
                value={class_location}
                onChange={handleClassLocationChange}
              />
              {/* {meta['class_location']} */}
            </Typography>
            <Typography variant="body2" color={calendar['color']}>
              <b> Class Time: </b>
              {/* <TextField
                autoFocus
                id="class_time"
                type="text"
                fullWidth
                variant="standard"
                value={class_time}
                onChange={handleClassTimeChange}
                /> */}
              <TextField
                id="name"
                // label="Class Time:"
                fullWidth
                size="small"
                variant="standard"
                onClick={(event) => {
                  handleClick(event);
                }}
                value={startTime}
                InputProps={{
                  style: { caretColor: 'transparent' },
                }}
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClosePop}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DigitalClock
                    // value={startTime}
                    onChange={(event) => {
                      let formattedDate = dayjs(event.$d).format('h:mm A');
                      setStartTime(formattedDate);
                      handleClosePop();
                    }}
                  />
                </LocalizationProvider>
              </Popover>
            </Typography>

            <Typography
              sx={{ mt: 2 }}
              variant="body2"
              color={calendar['color']}
            >
              <b> Calendar Color: </b>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={color}
                label="color"
                onChange={handleColorChange}
              >
                {colors.map((color, index) => (
                  <MenuItem key={index} value={color}>
                    <CircleIcon sx={{ color: color }} />
                  </MenuItem>
                ))}
              </Select>
            </Typography>
          </CardContent>
          <CardActions>
            <Button onClick={handleSave} color="success">
              <b>Save</b>
            </Button>
          </CardActions>
        </Card>
      </Container>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <PDFViewer
              fileURL={fileURL}
              eventStack={eventStack}
              key={calendar_id}
              eventsArray={eventsArray}
              searchFunction={searchFunction}
              calendar_id={calendar_id}
              setSearchFunction={setSearchFunction}
            />
          </Grid>
        </Grid>
      </Container>
      <Grid
        item
        justifyContent="flex-end"
        alignItems="flex-end"
        sx={{
          position: 'fixed',
          bottom: '0',
          right: '0',
          margin: '40px',
        }}
      >
        <Button
          to="/calendar"
          variant="contained"
          onClick={() => navigate('/calendar')}
        >
          Go to my calendar
        </Button>
      </Grid>
      <Grid
        item
        justifyContent="flex-start" // Align content to the start (left for horizontal, top for vertical)
        alignItems="flex-start" // Align items to the start
        sx={{
          position: 'fixed',
          top: '0', // Align to the top of the viewport
          left: '0', // Align to the left of the viewport
          margin: '40px', // Add margin to avoid sticking to the very edge
        }}
      >
        <Button variant="contained" onClick={() => navigate('/upload')}>
          Back
        </Button>
      </Grid>
    </>
  );
}

export default Syllabus;
