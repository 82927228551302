import React, { Suspense } from 'react';
import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import {
  CssBaseline,
  ThemeProvider,
  responsiveFontSizes,
  CircularProgress,
} from '@mui/material';
import { AuthProvider } from './components/API/AuthContext';
import { theme } from './components/Theme/Theme';

import Calendar from './pages/CalendarPage/Calendar';
import Login from './pages/LoginPage/Login';
// import Setup from "./pages/SetupPage/Setup";
import CalLayout from './layouts/calendar/calendar';
import Syllabus from './pages/SyllabusPage/Syllabus';
import Privacy from 'pages/PoliciesPage/Privacy.js';
import TermsOfService from 'pages/PoliciesPage/TermsOfService';
import ForgotPassword from 'pages/ForgotPage/ForgotPassword';
import ResetPassword from 'pages/ForgotPage/ResetPassword';
import { Authenticator } from '@aws-amplify/ui-react';
import {} from './auth.js';

import {
  signInWithRedirect,
  signOut,
  getCurrentUser,
  AuthUser,
} from 'aws-amplify/auth';

import { Amplify } from 'aws-amplify';
const Main = React.lazy(() => import('./layouts/main/main'));
const Home = React.lazy(() => import('./pages/HomePage/Home'));
const Upload = React.lazy(() => import('./pages/UploadPage/Upload'));
const NotFound = React.lazy(() =>
  import('./pages/ErrorPage/NotFound/NotFound'),
);
const Settings = React.lazy(() => import('./pages/SettingsPage/Settings'));
const Admin = React.lazy(() => import('./pages/AdminPage/Admin'));

let main_theme = responsiveFontSizes(theme);

const RedirectToExternalURL = () => {
  useEffect(() => {
    signInWithRedirect();
  }, []);

  return null;
};

function App() {
  return (
    <ThemeProvider theme={main_theme}>
      <CssBaseline />
      <Router>
        <AuthProvider>
          <Suspense fallback={<CircularProgress />}>
            <Routes>
              <Route exact path="/" element={<Navigate to="/Home" />} />
              <Route
                path="/Home"
                element={
                  <Main>
                    <Home />
                  </Main>
                }
              />
              <Route
                path="/Login"
                element={
                  <Main>
                    <Login />
                  </Main>
                }
              />
              <Route
                path="/Signup"
                element={
                  <Main>
                    <Login />
                  </Main>
                }
              />
              <Route path="/Upload" element={<Upload />} />
              <Route path="/Syllabus" element={<Syllabus />} />
              <Route
                path="/Calendar"
                element={
                  <CalLayout>
                    <Calendar />
                  </CalLayout>
                }
              />
              <Route path="/Admin" element={<Admin />} />
              <Route path="*" element={<NotFound />} />
              <Route
                path="/Settings"
                element={
                  <CalLayout>
                    <Settings />
                  </CalLayout>
                }
              />
              {/* <Route path="/Setup" element={<Setup />} /> */}
              <Route path="/Privacy" element={<Privacy />} />
              <Route path="/TermsOfService" element={<TermsOfService />} />
              <Route path="/ForgotPassword" element={<ForgotPassword />} />
              <Route path="/ResetPassword" element={<ResetPassword />} />
            </Routes>
          </Suspense>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
