import React, { useState, useEffect, useRef, useReducer } from 'react';
import { isLoggedIn } from '../../auth';
import {
  Button,
  Box,
  IconButton,
  Typography,
  Toolbar,
  AppBar,
  Snackbar,
  useMediaQuery,
  CssBaseline,
  ThemeProvider,
  Menu,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

// import {BsClock} from 'react-icons/bs';
import useOutsideClick from './useOutsideClick';
import useOutsideClickForDetailModal from './useOutsideClickForDetailModal';
import MuiAlert from '@mui/material/Alert';
import Calendar from '@toast-ui/react-calendar';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AuthContext from '../../components/API/AuthContext';
import Unauthorized from 'pages/ErrorPage/Unauthorized/Unauthorized';
import CustomModal from './components/CustomModal';
import CalNav from './components/CalNav';
import DesktopDrawer from './components/DesktopDrawer';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import { theme, darkTheme } from '../../components/Theme/Theme';

import 'tui-date-picker/dist/tui-date-picker.css';
import dayjs from 'dayjs';

import CustomDetailModal from './components/CustomDetailModal';
import {
  addUserEvent,
  getAllUserEvents,
  deleteUserEvent,
  updateUserEvent,
} from 'components/API/EventAPI';
import { getCalendars } from 'components/API/CalendarAPI';

import './css/styles.css';
import { ConnectedTvOutlined } from '@mui/icons-material';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,

    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${300}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function _getFormattedTime(time) {
  const date = new Date(time);
  let h = date.getHours();
  const t = h < 12 ? 'AM' : 'PM';
  let m = date.getMinutes();
  if (m === 0) {
    m = '00';
  }
  if (h !== 12 && t === 'PM') {
    h = h - 12;
  }
  return `${h}:${m} ` + t;
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const templates = {
  time: function (event) {
    console.log(event)
    if (event.raw) {
      return `
        <div class="event_bar" style="background-color: ${event.color};">
          <div style="color: black; text-decoration: line-through; opacity: 0.7;">
            ${_getFormattedTime(event.start)} ${event.body}
          </div>
        </div>
      `;
    } else if (event.isAllDay) {
      return '';
    } else {
      const startDate = event.start;
      const endDate = event.end;
      const startDateFormatted = _getFormattedTime(startDate);
      const endDateFormatted = _getFormattedTime(endDate);

      return `
        <div class="event_bar" style="background-color: ${event.color};">
          <div style="color: black;">
            ${event.body}
          </div>
        </div>
      `;
    }
  },

  allday: function (event) {
    if (event.raw) {
      return `
        <div class="event_bar" style="background-color: ${event.color};">
          <div style="color: black; text-decoration: line-through; opacity: 0.7;">
            ${event.title}
          </div>
        </div>
      `;
    } else if (event.isAllDay) {
      return `
        <div class="event_bar" style="background-color: ${event.color};">
          <div style="color: black;">
            ${event.title}
          </div>
        </div>
      `;
    } else {
      return `
      <div style="background-color:  ${event.color};">
        <div style="color: black;">
        ${event.title}
        </div>
      </div>
    `;
    }
  },
};

function _getFormattedMonth() {
  return new Date().getMonth();
}

function _getFormattedYear() {
  return new Date().getFullYear();
}

function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

function MasterCalendar() {
  const calendarRef = useRef();
  const divRef = useRef();
  const modalRef = useRef();
  //  const detaiModalRef = useRef();

  const [x, setX] = useState(900);
  const [y, setY] = useState(300);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [toolBarColor, setToolBarColor] = useState('#fffff');

  useEffect(() => {
    const handleClick = (e) => {
      if (e.target.tagName.toLowerCase() === 'div') {
        const rect = e.target.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
      }
    };
    if (divRef.current) divRef.current.addEventListener('click', handleClick);
    return () => {
      divRef?.current?.removeEventListener('click', handleClick);
    };
  }, []);

  const [startTime, setStartTime] = useState('12:00 AM');
  const [endTime, setEndTime] = useState('12:00 AM');
  const [calendarMounted, setCalendarMounted] = useState(false);
  useEffect(() => {
    console.log('Inside Calendar Js theming');
    console.log(calTheme);
    if (calTheme == 'dark' || localStorage.getItem('theme') == 'dark') {
      setToolBarColor('#262626');
      calendarRef.current.getInstance().setTheme({
        month: {
          moreView: {
            border: '1px solid 3F3F3F',
            backgroundColor: '#3F3F3F',
            width: 320,
            height: 200,
          },
          moreViewTitle: {
            color: 'white',
          },
          dayExceptThisMonth: {
            color: '#3F3F3F',
          },
          dayGrid: {
            borderRight: '1px dotted #000000',
          },
        },
        common: {
          border: '1px solid #000000',
          backgroundColor: '#262626',
          today: {
            color: '#ffffff',
            backgroundColor: '#ffffff',
          },
          dayName: {
            color: '#ffffff',
          },
          sunday: {
            color: '#ffffff',
          },
          saturday: {
            color: '#ffffff',
          },
          gridSelection: {
            border: '1px solid #A37CF0',
          },
        },
        week: {
          border: '1px solid #628f00',
          nowIndicatorLabel: {
            color: '#A37CF0',
          },
          nowIndicatorToday: {
            border: '1px solid #A37CF0',
          },
          nowIndicatorBullet: { backgroundColor: '#A37CF0' },
          futureTime: { color: '#ffffff' },
          dayGrid: {
            border: '1px solid #5b6370',
          },
          dayName: {
            borderLeft: '1px solid #e5e5e5',
            borderTop: '1px solid #e5e5e5',
            borderBottom: '1px solid #e5e5e5',
            backgroundColor: 'inherit',
          },
          dayGridLeft: {
            borderRight: '1px solid #e5e5e5',
            backgroundColor: 'inherit',
            width: '72px',
          },
          timeGridLeft: {
            backgroundColor: 'inherit',
            borderRight: '1px solid #5b6370',
            width: '72px',
          },
          timeGrid: {
            borderRight: '1px solid #5b6370',
            backgroundColor: '#262626',
            borderLeft: '1px solid #5b6370',
          },
          timeGridHourLine: {
            borderBottom: '1px solid #5b6370',
          },
        },
        day: {},
      });
      setCalendarMounted(true);
    }
    const lightTheme = {
      month: {
        moreView: {
          border: '1px solid #D1D1D1',
          backgroundColor: '#FFFFFF',
          width: 320,
          height: 200,
        },
        moreViewTitle: {
          color: '#333333',
        },
        dayExceptThisMonth: {
          color: '#D1D1D1',
        },
        dayGrid: {
          borderRight: '1px dotted #CCCCCC',
        },
      },
      common: {
        border: '1px solid #D1D1D1',
        backgroundColor: '#FFFFFF',
        today: {
          color: '#ffffff',
          backgroundColor: '#E2E2E2',
        },
        dayName: {
          color: '#333333',
        },
        sunday: {
          color: '#FF4500', // Retained a hint of red for Sunday
        },
        gridSelection: {
          border: '1px solid #D1D1D1',
        },
      },
      week: {
        border: '1px solid #D1D1D1',
        nowIndicatorLabel: {
          color: '#333333',
        },
        nowIndicatorToday: {
          border: '1px solid #D1D1D1',
        },
        nowIndicatorBullet: { backgroundColor: '#D1D1D1' },
        futureTime: { color: '#333333' },
        dayGrid: {
          border: '1px solid #E7E7E7',
        },
        dayName: {
          borderLeft: '1px solid #E7E7E7',
          borderTop: '1px solid #E7E7E7',
          borderBottom: '1px solid #E7E7E7',
          backgroundColor: '#FFFFFF',
        },
        dayGridLeft: {
          borderRight: '1px solid #E7E7E7',
          backgroundColor: '#FFFFFF',
          width: '72px',
        },
        timeGridLeft: {
          backgroundColor: '#FFFFFF',
          borderRight: '1px solid #E7E7E7',
          width: '72px',
        },
        timeGrid: {
          borderRight: '1px solid #E7E7E7',
          backgroundColor: '#FFFFFF',
          borderLeft: '1px solid #E7E7E7',
        },
        timeGridHourLine: {
          borderBottom: '1px solid #E7E7E7',
        },
      },
    };

    if (calTheme == 'light') {
      setToolBarColor('#fffff');
      calendarRef.current.getInstance().setTheme(lightTheme);
    }
  }, [calendarRef, calendarMounted, ignored]);

  const [myEvents, setMyEvents] = useState([]);
  const [month, setMonth] = useState(_getFormattedMonth());
  const [year, setYear] = useState(_getFormattedYear());
  const [view, setView] = useState('month');
  const [modal, setModal] = useState(false);
  const [detail, setDetail] = useState(false);
  const [event, setEvent] = useState(null);
  const [save, setSave] = useState('Add Event');
  const [title, setTitle] = useState('');
  const [startD, setStartD] = useState('');
  const [isCompleted, setIsCompleted] = useState('');
  const [endD, setEndD] = useState('');
  const [id, setId] = useState(null);
  const [calendarId, setCalendarId] = useState(null);
  const [desc, setDesc] = useState('');
  const [alert, setAlert] = useState(null);
  const [loggedIn, setLoggedIn] = useState(true);
  const [calendars, setCalendars] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [calTheme, setCalTheme] = useState(theme);
  const [open, setOpen] = useState(true);
  const [nextData, setNextData] = useState('');
  const [beforeData, setBeforeData] = useState('');
  const [todayData, setTodayData] = useState('');
  const [isAllDay, setIsAllDay] = useState(false);

  const nextClick = (currDate) => {
    setNextData({ currDate, view });
    setBeforeData('');
    setTodayData('');
  };

  const beforeClick = (currDate) => {
    setBeforeData({ currDate, view });
    setNextData('');
    setTodayData('');
  };

  const handleDrawer = (isOpen) => {
    setOpen(isOpen);
  };

  const handleAddModal = () => {
    setSave('Add Event');
    setModal(true);
    const event = {};
    // event.start = new Date();
    // event.end = new Date();
    onSelectDateTime(event);
  };

  const setBigCalendarDate = (date) => {
    const yearSelected = date.getFullYear();
    const monthSelected = date.getMonth();

    const calendar = calendarRef.current.getInstance();
    calendar.setDate(date);
    setMonth(monthSelected);
    setYear(yearSelected);
  };

  const handleSmallDateClick = (date) => {
    setBigCalendarDate(date);
  };
  const isSm = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    isLoggedIn().then((res) => {
      setLoggedIn(res);
      console.log(loggedIn);
    });
  }, []);

  React.useLayoutEffect(() => {
    if (loggedIn) {
      getAllUserEvents().then((events) => {
        if (typeof events === 'object') {
          events.forEach((event) => {
            event.calendarId = event.calendar;
            event.raw = event.isCompleted;
          });
          getCalendars().then((mycalendars) => {
            var tempCal = [];
            mycalendars.forEach((calendar) => {
              tempCal.push({
                id: calendar.id,
                name: calendar.name,
                bgColor: calendar.color,
                backgroundColor: calendar.color,
                borderColor: calendar.color,
                isVisible: true,
                class_name: calendar.meta.class_name,
              });
              events.forEach((event) => {
                if (calendar.id === event.calendarId) {
                  event.color = calendar.color;
                  event.isVisible = calendar.isVisible;
                  event.isPrivate = true;
                }
              });
            });

            events.forEach((event) => {
              event.start = event.start.replace("Z","-05:00");
              event.end = event.end.replace("Z","-05:00");
            });
            setMyEvents(events);
            setCalendars(tempCal);
            if (localStorage.getItem('theme') === 'dark') {
              setCalTheme(darkTheme);
            } else {
              setCalTheme(theme);
            }
          });
        }
      });
    }
  }, [calendarMounted, ignored, loggedIn]);

  const onDeleteEvent = async () => {
    var eId = String(id);
    let status = false;
    await deleteUserEvent(eId).then((response) => {
      if (response !== 'Success') {
        setOpenSnack(true);
        status = true;
        setAlert('Unable to delete event');
        return;
      }
    });
    if (!status) {
      calendarRef.current.getInstance().deleteEvent(id, calendarId);
    }
    setEvent(null);
    setTitle('');
    setDesc('');
    setId(null);
    setCalendarId(null);
    setStartD('');
    setEndD('');
    setDetail(!detail);
  };

  const onMarkComplete = async (event) => {
    console.log(event);
    const res = {
      id: event.id,
      calendarId: event.calendarId,
      title: event.title,
      start: event.start,
      end: event.end,
      body: event.body,
      category: isAllDay ? 'allday' : 'time',
      isCompleted: event.isCompleted,
      changes: false,
    };
    console.log('res on mark complete');
    console.log(res);

    onBeforeUpdateEvent(res);
  };

  const onCreateEvent = async (res) => {
    let status = false;
    const color = calendars.find(
      (calendar) => calendar.id === res.calendarId,
    ).bgColor;
    let eventInfo = {
      calendar: res.calendarId,
      title: res.title,
      start: res.start,
      end: res.end,
      body: res.body,
      category: res.category,
    };
    // console.log()
    console.log(eventInfo);
    await addUserEvent(eventInfo).then((response) => {
      if (response['message'] === 'Success') {
        getAllUserEvents().then((response) => {
          if (typeof response === 'object') {
            response.forEach((event) => {
              event.calendarId = event.calendar;
            });
            calendars.forEach((calendar) => {
              response.forEach((event) => {
                if (calendar.id === event.calendarId) {
                  event.color = calendar.bgColor;
                  event.isVisible = calendar.isVisible;
                }
              });
            });
            response.forEach((event) => {
              event.start = event.start.replace("Z","-05:00");
              event.end = event.end.replace("Z","-05:00");
            });
            setMyEvents(response);
          } else {
            status = true;
            setOpenSnack(true);
            setAlert('Unable to get events');
          }
        });
      } else {
        status = true;
        setOpenSnack(true);
        setAlert('Unable to add event');
      }
    });
    if (!status) {
      eventInfo.color = color;
      calendarRef.current.getInstance().createEvents([eventInfo]);
    }
    calendarRef.current.getInstance().clearGridSelections();
    setEvent(null);
    setModal(!modal);
    setTitle('');
    setDesc('');
    setId(null);
    setCalendarId(null);
    setStartD('');
    setEndD('');
  };

  const onBeforeUpdateEvent = (res) => {
    if (res.event === undefined && res.changes === undefined) {
      setSave('Update Event');
      setDetail(false);
      setModal(true);
    } else {
      onUpdateEvent(res);
    }
  };

  const onUpdateEvent = async (res) => {
    let curr_event = null;
    let curr_changes = null;
    let status = false;
    if (res.event) {
      curr_event = res.event;
    }
    if (res.changes) {
      curr_changes = res.changes;
    }
    let updatedEvent = null;
    if (curr_event && curr_changes) {
      updatedEvent = calendarRef.current
        .getInstance()
        .getEvent(curr_event.id, curr_event.calendarId);
      updatedEvent.id = curr_event.id;
      updatedEvent.body = curr_event.body;
      if (curr_changes.start) {
        updatedEvent.start = curr_changes.start;
      } else {
        updatedEvent.start = curr_event.start;
      }
      if (curr_changes.end) {
        updatedEvent.end = curr_changes.end;
      } else {
        updatedEvent.end = curr_event.end;
      }
      if (curr_changes.calendarId) {
        updatedEvent.calendar = curr_changes.calendarId;
      } else {
        updatedEvent.calendar = curr_event.calendarId;
      }
      updatedEvent.isCompleted = curr_event.isCompleted;
    } else {
      updatedEvent = res;
      updatedEvent.calendar = updatedEvent.calendarId;
    }
    await updateUserEvent(updatedEvent).then((response) => {
      if (response !== 'Success') {
        setOpenSnack(true);
        status = true;
        setAlert('Unable to update event');
      }
    });
    if (!status) {
      if (updatedEvent.isCompleted !== undefined) {
        updatedEvent.raw = updatedEvent.isCompleted;
      }

      calendarRef.current
        .getInstance()
        .updateEvent(updatedEvent.id, updatedEvent.calendar, updatedEvent);
    }
    setModal(false);
    setTitle('');
    setDesc('');
    setId(null);
    setCalendarId(null);
    setStartD('');
    setEndD('');
    setEvent(null);
    setDetail(false);
    forceUpdate();
  };

  const handleNextButton = () => {
    const calendarInstance = calendarRef.current?.getInstance();
    // console.log(calendarInstance)
    if (calendarInstance) {
      console.log(calendarInstance);

      if (view === 'day') {
        const currDay = calendarInstance.getDate().getDate();
        const numDays = daysInMonth(month + 1, year);
        if (currDay === numDays) {
          if (month === 0) {
            setMonth(11);
            setYear(year + 1);
          } else {
            setMonth(month + 1);
          }
        }
      } else if (view === 'week') {
        const weekStartDay = calendarRef.current
          .getInstance()
          .getDateRangeStart()
          .getDate();
        const weekEndDay = calendarRef.current
          .getInstance()
          .getDateRangeEnd()
          .getDate();
        const weekMonth = calendarRef.current
          .getInstance()
          .getDateRangeEnd()
          .getMonth();
        if (weekStartDay > weekEndDay && weekMonth !== month) {
          if (month === 11) {
            setMonth(0);
            setYear(year + 1);
          } else {
            setMonth(month + 1);
          }
        }
      } else {
        if (month === 11) {
          setMonth(0);
          setYear(year + 1);
        } else {
          setMonth(month + 1);
        }
      }
      nextClick(calendarRef.current.getInstance().getDate().d.d);
      calendarRef.current.getInstance().next();
    }
  };

  const handlePrevButton = () => {
    const calendarInstance = calendarRef.current?.getInstance();

    if (calendarInstance) {
      if (view === 'day') {
        const currDay = calendarInstance.getDate().getDate();
        const numDays = daysInMonth(month + 1, year);
        if (currDay === numDays) {
          if (month === 0) {
            setMonth(11);
            setYear(year - 1);
          } else {
            setMonth(month - 1);
          }
        }
      } else if (view === 'week') {
        const weekStartDay = calendarInstance.getDateRangeStart().getDate();
        const weekEndDay = calendarInstance.getDateRangeEnd().getDate();
        const weekMonth = calendarInstance.getDateRangeStart().getMonth();
        if (weekStartDay > weekEndDay && weekMonth !== month) {
          if (month === 0) {
            setMonth(11);
            setYear(year - 1);
          } else {
            setMonth(month - 1);
          }
        }
      } else {
        if (month === 0) {
          setMonth(11);
          setYear(year - 1);
        } else {
          setMonth(month - 1);
        }
      }
      beforeClick(calendarInstance.getDate().d.d);
      calendarInstance.prev();
    }
  };

  const handleTodayButton = () => {
    setMonth(_getFormattedMonth());
    setYear(_getFormattedYear());
    calendarRef.current.getInstance().today();
    setNextData('');
    setBeforeData('');
    setTodayData(new Date());
  };

  const handleDayButton = () => {
    setView('day');
    calendarRef.current.getInstance().changeView('day');
  };

  const handleWeekButton = () => {
    setView('week');
    calendarRef.current.getInstance().changeView('week');
  };

  const handleMonthButton = () => {
    if (view === 'week') {
      setMonth(calendarRef.current.getInstance().getDateRangeEnd().getMonth());
    }
    setView('month');
    calendarRef.current.getInstance().changeView('month');
  };

  const onSelectDateTime = (event) => {
    console.log('inside on Selectt Date');

    setSave('Add Event');
    setTitle('');
    setDesc('');
    setStartD(moment(event.start).utcOffset(0, true).format().replace('Z', ''));
    setEndD(moment(event.end).utcOffset(0, true).format().replace('Z', ''));
    setEvent(event);
    setModal(!modal);
  };

  const onClickEvent = (event) => {
    console.log('On click event');
    console.log(event);
    setTitle(event.event.title);
    setStartD(
      moment(new Date(event.event.start))
        .utcOffset(0, true)
        .format()
        .replace('Z', ''),
    );
    setEndD(
      moment(new Date(event.event.end))
        .utcOffset(0, true)
        .format()
        .replace('Z', ''),
    );

    if (event.category === 'allday') {
      setIsAllDay(true);
    } else {
      setIsAllDay(false);
    }

    setIsCompleted(event.event.raw);
    setId(event.event.id);
    setCalendarId(event.event.calendarId);
    setDesc(event.event.body);
    setEvent(event.event);
    setDetail(!detail);
  };

  const handleModalCreateClose = () => {
    setModal(false);
    setTitle('');
    setDesc('');
    setId(null);
    setCalendarId(null);
    setStartD('');
    setEndD('');
    calendarRef.current.getInstance().clearGridSelections();
  };
  useOutsideClick(modalRef, handleModalCreateClose);
  const [calendarKey, setCalendarKey] = useState('');

  const handleFilterCalendar = (calendarId) => {
    var visibility = false;
    const updatedCalendars = calendars.map((calendar) => {
      if (calendar.id === calendarId) {
        visibility = !calendar.isVisible;
        return { ...calendar, isVisible: !calendar.isVisible };
      }
      return calendar;
    });
    setCalendars(updatedCalendars);
    calendarRef.current
      .getInstance()
      .setCalendarVisibility(calendarId, visibility);
  };
  const toggleDetail = () => {
    console.log('Closing View');
    console.log(detail);
    setDetail(!detail);
    console.log(detail);
    setEvent(null);
    setTitle('');
    setDesc('');
    setId(null);
    setCalendarId(null);
    setStartD('');
    setEndD('');
    calendarRef.current.getInstance().clearGridSelections();
  };
  // useOutsideClickForDetailModal(detaiModalRef, toggleDetail)

  return (
    <ThemeProvider theme={calTheme}>
      <CssBaseline />
      <Box>
        {loggedIn ? (
          <Box sx={{ display: 'flex' }}>
            <DesktopDrawer
              calendars={calendars}
              onChange={handleFilterCalendar}
              openDrawer={open}
              onCreate={handleAddModal}
              onSmallDateClick={handleSmallDateClick}
              nextClick={nextData}
              beforeClick={beforeData}
              setOpenDrawer={setOpen}
              todayClick={todayData}
              forceUpdate={forceUpdate}
              setCalTheme={setCalTheme}
            />
            <Main open={open}>
              {openSnack ? (
                <Snackbar
                  open={openSnack}
                  autoHideDuration={6000}
                  onClose={() => setOpenSnack(false)}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Alert
                    onClose={() => setOpenSnack(false)}
                    severity="error"
                    sx={{ width: '100%' }}
                  >
                    {alert}
                  </Alert>
                </Snackbar>
              ) : (
                <></>
              )}

              <Toolbar
                sx={{
                  justifyContent: 'space-between',
                  backgroundColor: toolBarColor,
                }}
              >
                <Box>
                  {!open && (
                    <IconButton
                      disableRipple={true}
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      <MenuIcon />
                    </IconButton>
                  )}
                  <IconButton onClick={handlePrevButton}>
                    <ArrowBackIosIcon />
                  </IconButton>
                  <IconButton onClick={handleNextButton}>
                    <ArrowForwardIosIcon />
                  </IconButton>
                  <Typography variant="h7" color="primary">
                    {months[month]} {year}
                  </Typography>
                </Box>
                <Box>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleTodayButton}
                  >
                    Today
                  </Button>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleDayButton}
                  >
                    Day
                  </Button>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleWeekButton}
                  >
                    Week
                  </Button>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleMonthButton}
                  >
                    Month
                  </Button>
                </Box>
              </Toolbar>
              <div ref={divRef} className="cal_container">
                <Calendar
                  container={calendarRef}
                  ref={calendarRef}
                  key={calendarKey}
                  view="month"
                  events={myEvents}
                  calendars={calendars}
                  useDetailPopup={false}
                  useFormPopup={false}
                  // height="auto"
                  width="auto"
                  scheduleView={['time', 'allday']}
                  week={{
                    taskView: false,
                  }}
                  template={templates}
                  onBeforeCreateEvent={onCreateEvent}
                  onBeforeDeleteEvent={onDeleteEvent}
                  onBeforeUpdateEvent={onBeforeUpdateEvent}
                  onSelectDateTime={(e) => {
                    onSelectDateTime(e);
                  }}
                  onClickEvent={onClickEvent}
                  scrollToNow={true}
                  navigation={true}
                  useScrollDirection={true}
                  usageStatistics={false}
                  onMarkComplete={onMarkComplete}
                />
              </div>

              {/* <div ref={modal? modalRef: null}> */}
              <CustomModal
                {...{
                  isOpen: modal,
                  handleClose: handleModalCreateClose,
                  onSubmit:
                    save === 'Add Event' ? onCreateEvent : onUpdateEvent,
                  submitText: save,
                  calendars: calendars,
                  event: event,
                  des: desc,
                  startDate: startD,
                  endDate: endD,
                  posx: x,
                  posy: y,
                  forceUpdate: forceUpdate,
                }}
              />
              {/* </div> */}

              {/* </div> */}
              {/* <div ref={detail?detaiModalRef: null}> */}
              <CustomDetailModal
                {...{
                  isOpen: detail,
                  handleClose: toggleDetail,
                  title: title,
                  desc: desc,
                  startDate: startD,
                  endDate: endD,
                  onDeleteClick: onDeleteEvent,
                  onEditClick: onBeforeUpdateEvent,
                  onMarkComplete: onMarkComplete,
                  isCompleted: isCompleted,
                  calendars: calendars,
                  calendarId: calendarId,
                  isAllDay: isAllDay,
                  id: id,
                  posx: x,
                  posy: y,
                }}
              />
              {/* </div>w */}
            </Main>
          </Box>
        ) : (
          <Unauthorized />
        )}
      </Box>
    </ThemeProvider>
  );
}

export default MasterCalendar;
